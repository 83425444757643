<template>
  <div>
    <v-container fluid class="py-8">
      <Notify ref="myNotify" :details="message"></Notify>
      <v-card color="light" v-if="inquiries_data.length > 0">
        <v-app-bar color="light" class="border-left-primary">
          <v-toolbar-title
            >{{ $t("order.Orders") }} # {{ this.$route.params.id }}
          </v-toolbar-title>
        </v-app-bar>

        <v-card>
          <Loading v-if="loading"></Loading>
          <v-card-text class="mt-5 pb-0">
            <v-container>
              <v-form ref="form">
                <v-row>
                  <v-col
                    class="pt-0"
                    v-for="(item, index) in inqueries"
                    :key="index"
                    cols="4"
                  >
                    <label class="text-xs text-typo font-weight-bolder ms-1">{{
                      item.inquery_lang
                    }}</label>
                    <v-file-input
                      v-if="item.type == 'file'"
                      v-model="inquiries_data[index].answer"
                      show-size
                      counter
                      chips
                    ></v-file-input>
                    <v-text-field
                      v-else
                      :type="item.type"
                      outlined
                      color="rgba(0,0,0,.6)"
                      class="
                        font-size-input
                        placeholder-lighter
                        text-light-input
                      "
                      v-model="inquiries_data[index].answer"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions class="justify-end pb-5">
            <!-- <v-spacer></v-spacer> -->
            <v-btn
              :loading="loader"
              class="btn-primary"
              color="white"
              text
              @click="save_form()"
            >
              <span slot="loader">
                <v-progress-circular
                  style="width: 20px; height: 20px"
                  indeterminate
                  color="white"
                ></v-progress-circular>
              </span>
              {{ $t("form.save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>

      <v-card color="light" class="mt-6">
        <v-app-bar color="light" class="border-left-primary">
          <v-toolbar-title>{{ $t("inquiry.Inqieries") }} </v-toolbar-title>
        </v-app-bar>
        <Table
          :loading="loading"
          :headers="headers"
          :items="inqueries_done"
        ></Table>
      </v-card>
    </v-container>
  </div>
</template>
<script>
// import Table from "./TableOrder.vue";
import Notify from "../Components/Old/Notify.vue";
import Table from "../Components/Table.vue";
import Loading from "../Components/Loading.vue";
import CustomerService from "../../services/customer.service";
export default {
  name: "inquiries-Page",
  components: {
    Table,
    Notify,
    Loading,
  },
  data() {
    return {
      loading: true,
      message: {
        msg: null,
        type: null,
      },
      loader: false,
      inqueries: [],
      inquiries_data: [],
      inqueries_done: [],
      headers: [
        {
          text: this.$i18n.t("inquiry.Inquery Text"),
          value: "inquery_lang",
          align: "center",
        },
        {
          text: this.$i18n.t("inquiry.answer"),
          value: "answer",
          align: "center",
        },
      ],
    };
  },

  methods: {
    get_orders() {
      return CustomerService.get_order_inquiries(this.$route.params.id).then(
        (response) => {
          // console.log(response);
          if (response.data.code == "405") {
            this.message = {
              msg: response.data.message,
              type: "Danger",
            };
          } else {
            this.inqueries_done = response.data.inquiries;
            this.inqueries = response.data.inquiries_null;
            for (let i = 0; i < this.inqueries.length; i++) {
              const element = this.inqueries[i];
              this.inquiries_data.push({
                id: element.inquery_id,
                answer: null,
                type: element.type,
              });
            }
          }
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          console.log(error);
          this.message = {
            msg: this.$i18n.t("general.there is problem"),
            type: "Danger",
          };
        }
      );
    },
    save_form() {
      this.loader = true;
      // let data = this.inquiries_data;
      // console.log(data);
      var formData = new FormData();
      for (let i = 0; i < this.inquiries_data.length; i++) {
        const element = this.inquiries_data[i];
        formData.append(`answer[${i}]`, element.answer);
        formData.append(`id[${i}]`, element.id);
        formData.append(`types[${i}]`, element.type);
      }
      console.log(...formData);
      return CustomerService.save_inquiry_answer(
        formData,
        // {
        //   inquiries: formData,
        // },
        this.$route.params.id
      ).then(
        (response) => {
          this.loader = false;
          // console.log(response);
          if (response.data.code == "405") {
            this.message = {
              msg: response.data.message,
              type: "Danger",
            };
          } else {
            for (let i = 0; i < this.inquiries_data.length; i++) {
              const element = this.inquiries_data[i];
              console.log(element);
              if (element.answer != null) {
                this.inqueries = this.inqueries.filter(
                  (v) => v.inquery_id != element.id
                );
                this.inquiries_data.splice(i, 1);
                i--;
              }
            }
            this.inqueries_done = this.inqueries_done.concat(
              response.data.data
            );
            this.message = {
              msg: response.data.message,
              type: "Success",
            };
          }
          this.loading = false;
        },
        (error) => {
          this.loader = false;
          this.loading = false;
          console.log(error);
          this.message = {
            msg: this.$i18n.t("general.there is problem"),
            type: "Danger",
          };
        }
      );
    },
  },
  mounted() {
    this.get_orders();
    document.title = this.$i18n.t("inquiry.Inqieries");
  },
};
</script>
